const { Buffer } = require("buffer");
const bs58 = require("bs58");
const forge = require("node-forge");
const elliptic = require("elliptic");
const { ec } = elliptic;

const animationHolder = document.getElementById("lottie");
// Lottie will be setup at end of file,depending on AMD/NVIDIA
let verifiedAnimation = null;

// Certificate is hard coded due to CORS issues
// Cross-Origin Request Blocked:
// The Same Origin Policy disallows reading the remote resource
// at https://kdsintf.amd.com/vcek/v1/Milan/8EDCE1ED7D78A88D92122C5D6658EF3331872AB0C265B7BD06C7AA479E3673AD882CDC9AC2E54CA55AED714589A6038806B8B52C4C88B7AFB01049E1DE8BC6AA?blSPL=02&teeSPL=00&snpSPL=20&ucodeSPL=209.
// (Reason: CORS header ‘Access-Control-Allow-Origin’ missing).
// Status code: 200
let vcek_pem_db = {
  "1cfe25683c53c3611f7199149c4fd0c8535fe529b7ff763141a4a929f1934e7715b920576a5255a49ce100492aa86165d14735e8127882fec2be2b9e5f0c816a": {
    "blSPL03teeSPL00snpSPL08ucodeSPL206":`
-----BEGIN CERTIFICATE-----
MIIFTTCCAvygAwIBAgIBADBGBgkqhkiG9w0BAQowOaAPMA0GCWCGSAFlAwQCAgUA
oRwwGgYJKoZIhvcNAQEIMA0GCWCGSAFlAwQCAgUAogMCATCjAwIBATB7MRQwEgYD
VQQLDAtFbmdpbmVlcmluZzELMAkGA1UEBhMCVVMxFDASBgNVBAcMC1NhbnRhIENs
YXJhMQswCQYDVQQIDAJDQTEfMB0GA1UECgwWQWR2YW5jZWQgTWljcm8gRGV2aWNl
czESMBAGA1UEAwwJU0VWLU1pbGFuMB4XDTI0MDYxMjE0MjkwMloXDTMxMDYxMjE0
MjkwMlowejEUMBIGA1UECwwLRW5naW5lZXJpbmcxCzAJBgNVBAYTAlVTMRQwEgYD
VQQHDAtTYW50YSBDbGFyYTELMAkGA1UECAwCQ0ExHzAdBgNVBAoMFkFkdmFuY2Vk
IE1pY3JvIERldmljZXMxETAPBgNVBAMMCFNFVi1WQ0VLMHYwEAYHKoZIzj0CAQYF
K4EEACIDYgAEoneMo72xqtr0LVuKrZfza7xkvLJkjmKxuUNbAemlaLwX26EYzXUm
cixMopkU+7c6QEQogaaky65ZLAFXFEy56rvAvvDfwAHCDIYRj6aIfD6tvER6W0hG
vPXLHgOFPRwGo4IBFzCCARMwEAYJKwYBBAGceAEBBAMCAQAwFwYJKwYBBAGceAEC
BAoWCE1pbGFuLUIwMBEGCisGAQQBnHgBAwEEAwIBAzARBgorBgEEAZx4AQMCBAMC
AQAwEQYKKwYBBAGceAEDBAQDAgEAMBEGCisGAQQBnHgBAwUEAwIBADARBgorBgEE
AZx4AQMGBAMCAQAwEQYKKwYBBAGceAEDBwQDAgEAMBEGCisGAQQBnHgBAwMEAwIB
CDASBgorBgEEAZx4AQMIBAQCAgDOME0GCSsGAQQBnHgBBARAHP4laDxTw2EfcZkU
nE/QyFNf5Sm3/3YxQaSpKfGTTncVuSBXalJVpJzhAEkqqGFl0Uc16BJ4gv7Cviue
XwyBajBGBgkqhkiG9w0BAQowOaAPMA0GCWCGSAFlAwQCAgUAoRwwGgYJKoZIhvcN
AQEIMA0GCWCGSAFlAwQCAgUAogMCATCjAwIBAQOCAgEAF05AEPN2L7jixomvkIX/
Xah7CWXBKV+LlgRhNejJflVr688jL89VdvBpHwPNWIJAVzFNnVwlILwP88ntBfRy
x5VfoKK+/Lwdosg/zbKZLTcSdf3OP41POu3XE6JTuD3/q4UFrYpo6luenm84kz23
/D+GjUrCZ1nU26B4OB+CLFt0LdfcShEkFaquJZQDvVpwRGsoh68X02QDLUjg8TIX
eL7+kGRuhFq+ZrK1mXbPmtYWiDtaPCniq8RsfZskUg04EuTwthATuS/OKNzQDIM5
e0UbNBMuWrg4y4EQFR4kj6CXTsJXkbMYfBM+BjDfE0Ftv1Gqc5SI0NrE24bSM6Zr
b6AOfZ9AVZmiw1KsZgQiVkQ4Hf5xJ/HQ3RU+yb+IOjcbAGM7WdalWIu8n43qywrc
8O4o9KksuL20h5mD1PsEIdflg/64qVi4r9IqusakKQv8br8C43vOdSoVAlZGtq3m
NaboOciz0j5SS/WV4oTZPdeNjNu4cT6GJ8t3NxCvhdJrA/NZVPTpo0BLSoVZ7zaA
UQhGNF9eyswzs896f+oYXUjZcibwc1ibO66vUE5Gb2XekgurkZhZTUSmTKvHDwC1
HEOJ7HJ3KXpRY8LSMtngflAgFXj1ZITXUy3aIUnpAzG+QvTcu5Rwd/tuDs8iLxOh
EC6eSVLUqfXZFmpgsfQAwTQ=
-----END CERTIFICATE-----`
  },
  "0da04c37c7c02832e18f0b67ce7acf2611b3f23a2dcaa710edc9e8dd53b4c75f144bcb794343428f5fbb75b5c1715197b362fdd574bc52a62a0b76df63ffbe64": {
    "blSPL03teeSPL00snpSPL20ucodeSPL209": `
-----BEGIN CERTIFICATE-----
MIIFTTCCAvygAwIBAgIBADBGBgkqhkiG9w0BAQowOaAPMA0GCWCGSAFlAwQCAgUA
oRwwGgYJKoZIhvcNAQEIMA0GCWCGSAFlAwQCAgUAogMCATCjAwIBATB7MRQwEgYD
VQQLDAtFbmdpbmVlcmluZzELMAkGA1UEBhMCVVMxFDASBgNVBAcMC1NhbnRhIENs
YXJhMQswCQYDVQQIDAJDQTEfMB0GA1UECgwWQWR2YW5jZWQgTWljcm8gRGV2aWNl
czESMBAGA1UEAwwJU0VWLU1pbGFuMB4XDTI0MDIyMzIyNTAyOVoXDTMxMDIyMzIy
NTAyOVowejEUMBIGA1UECwwLRW5naW5lZXJpbmcxCzAJBgNVBAYTAlVTMRQwEgYD
VQQHDAtTYW50YSBDbGFyYTELMAkGA1UECAwCQ0ExHzAdBgNVBAoMFkFkdmFuY2Vk
IE1pY3JvIERldmljZXMxETAPBgNVBAMMCFNFVi1WQ0VLMHYwEAYHKoZIzj0CAQYF
K4EEACIDYgAEDjjGusbK2vJR4H9TngAet/lRzz14BNKczBMLmtzmfTL2XKrkqZth
46VDA0IisK9KtAc7WMnSLkcJq/EdLtCyZhUVP7b/aTO9bd2MXQBdX2nATeCShDFF
lJlTfkJljEqWo4IBFzCCARMwEAYJKwYBBAGceAEBBAMCAQAwFwYJKwYBBAGceAEC
BAoWCE1pbGFuLUIwMBEGCisGAQQBnHgBAwEEAwIBAzARBgorBgEEAZx4AQMCBAMC
AQAwEQYKKwYBBAGceAEDBAQDAgEAMBEGCisGAQQBnHgBAwUEAwIBADARBgorBgEE
AZx4AQMGBAMCAQAwEQYKKwYBBAGceAEDBwQDAgEAMBEGCisGAQQBnHgBAwMEAwIB
FDASBgorBgEEAZx4AQMIBAQCAgDRME0GCSsGAQQBnHgBBARADaBMN8fAKDLhjwtn
znrPJhGz8jotyqcQ7cno3VO0x18US8t5Q0NCj1+7dbXBcVGXs2L91XS8UqYqC3bf
Y/++ZDBGBgkqhkiG9w0BAQowOaAPMA0GCWCGSAFlAwQCAgUAoRwwGgYJKoZIhvcN
AQEIMA0GCWCGSAFlAwQCAgUAogMCATCjAwIBAQOCAgEAjsYukPfn6L+j8uzGV6JB
9WA5C8YDKGXHVHV8Qg/n3E0VwlBW74teMzRthsn6UsrwSmCFHuUcAXaHKwxJsppC
2820au/GT8wqJPvC1rh7tzNv3P1Sq3kWZZFu2W+IqJehAJL7A6C3q1/eGTWk/lMa
ndIjqix4KYsj2EY/LA48tDwJgAPbNYemzgFCPhfmkjVw2XI9Uv3aRAr7l/dJMnzi
7k/F4Ldb69r6hJwg5AdLzgtxMkU8C1lA9aCnsxymFqIkTbUOZSyvSzwOlRbYJS8B
cZpIiOLCuA2IrIj2fi/ihypjprFAD1iXdmqAmrZ4oSAn+u/7JUQveThsiCfDVeaY
aL6GDW2pBIkVBx5SqHBqHXaKPzfJCvytMLG3nUF0Ke8fnuTyczyGvnQ3nWumkz5n
cxNI1gdf0dYxShn88MVWzvVRVTzIlr2CLpucmHg+0MqwRbVNqEekofZFS1rKpGmK
b2q41Rmohb8hsNlgSEqHMGXz+quIkZt4ZGx4jrg9xziplYqOM6oLhuo0dtelqaBE
miBkfIxuC1euK6HNlAchNqwtAkKsAQFdSyVHrWCu/meJFR9YaSE0yjkgDm8nn0eP
PoZezAA3C7mM5ZvbmBgDhaZq5QhtaY7TkNO+kly3eBE5KcSZw0te95CSLkfaVbDS
93CHgB33zpH7q7mVH+FsDPY=
-----END CERTIFICATE-----
`    
  },
"8edce1ed7d78a88d92122c5d6658ef3331872ab0c265b7bd06c7aa479e3673ad882cdc9ac2e54ca55aed714589a6038806b8b52c4c88b7afb01049e1de8bc6aa": {
    "blSPL02teeSPL00snpSPL20ucodeSPL209": `
-----BEGIN CERTIFICATE-----
MIIFTTCCAvygAwIBAgIBADBGBgkqhkiG9w0BAQowOaAPMA0GCWCGSAFlAwQCAgUA
oRwwGgYJKoZIhvcNAQEIMA0GCWCGSAFlAwQCAgUAogMCATCjAwIBATB7MRQwEgYD
VQQLDAtFbmdpbmVlcmluZzELMAkGA1UEBhMCVVMxFDASBgNVBAcMC1NhbnRhIENs
YXJhMQswCQYDVQQIDAJDQTEfMB0GA1UECgwWQWR2YW5jZWQgTWljcm8gRGV2aWNl
czESMBAGA1UEAwwJU0VWLU1pbGFuMB4XDTI0MDEyNzE2MzU1OVoXDTMxMDEyNzE2
MzU1OVowejEUMBIGA1UECwwLRW5naW5lZXJpbmcxCzAJBgNVBAYTAlVTMRQwEgYD
VQQHDAtTYW50YSBDbGFyYTELMAkGA1UECAwCQ0ExHzAdBgNVBAoMFkFkdmFuY2Vk
IE1pY3JvIERldmljZXMxETAPBgNVBAMMCFNFVi1WQ0VLMHYwEAYHKoZIzj0CAQYF
K4EEACIDYgAEeGkBsKA2+ac7G8yVgXomiAQQAgHZcGkH9i8uM5CTEHqqGPGwftaz
4Qq1H6buvfeEHAXLFUC+PCVkj5uGTMqexciB7/1/nE5jbkLlpJa6ovBi0zk6ZuQ+
aFP9CGOufG9To4IBFzCCARMwEAYJKwYBBAGceAEBBAMCAQAwFwYJKwYBBAGceAEC
BAoWCE1pbGFuLUIwMBEGCisGAQQBnHgBAwEEAwIBAjARBgorBgEEAZx4AQMCBAMC
AQAwEQYKKwYBBAGceAEDBAQDAgEAMBEGCisGAQQBnHgBAwUEAwIBADARBgorBgEE
AZx4AQMGBAMCAQAwEQYKKwYBBAGceAEDBwQDAgEAMBEGCisGAQQBnHgBAwMEAwIB
FDASBgorBgEEAZx4AQMIBAQCAgDRME0GCSsGAQQBnHgBBARAjtzh7X14qI2SEixd
ZljvMzGHKrDCZbe9BseqR542c62ILNyawuVMpVrtcUWJpgOIBri1LEyIt6+wEEnh
3ovGqjBGBgkqhkiG9w0BAQowOaAPMA0GCWCGSAFlAwQCAgUAoRwwGgYJKoZIhvcN
AQEIMA0GCWCGSAFlAwQCAgUAogMCATCjAwIBAQOCAgEAB9lsIt0mJ2uM5QpiPHnQ
FsG1zV9B+zHgxHE3inG3MKwyqW+VHM5+4IHAOKKg3/6yUZPOGRRoVsRx63N4Dkfb
RzryEP669Wnphy7z0PlLCRmUG7ExMnO2V1boO6zMOYyVlKWkrwHSqJaeOPrYOnvu
RZR+pGfQS4GnSA7b+JoNA3MK/rMgJx4xd8pQWcaLPV8X4akVskIH+W9HoI/cF2kf
8KSSv3H50PInzEx2FmjL+YcyNEksOI5WcfwU4BZ9qKnWGrWJJN27alVSFTtLBdml
FVbzhe+ZFrnsbKNzkySr3DyKy6vsrD/AQSvirEWWY9KLnEUML1ydLhDOakPalDoM
qpQ9PgdcefVWua4Kj+ETGLiJGfIoiPFAr4ZLV0WqH3hvuloccAQOWkjF20fjbEzU
TyxVLWnACGQcmb9GTS2NptIg01B2Gkyaj3AHVCRQXtP44jL50pP/p/3vsC6IV4rQ
bZFRPa8alWv+Pux+NHOwOX4Y+vkne6Kn9tP/OMlrNtS8KMdS7Im4Mt2uRw4/2lNK
HHg1Fu39MnZc6XbcpZ0OO8DMoVRXA++z+KMmMBG0OazzWOxvJJU6Gg5HEvUJORrD
A2bJIBOzKKdvURX+3hoadQpXNUkxMiQjyXI1iUmmt1GwAPeXCG1q07QtBCo8M/Ly
mNGj6a+RXBE/lz+DAOpgo5o=
-----END CERTIFICATE-----
`
  }
}

// Load local keys
const value = localStorage.getItem('vcomp-vcek');
if (value) {
  let local_certs = JSON.parse(value)
  vcek_pem_db = Object.assign({}, vcek_pem_db,local_certs);
}

// Certificate is hard coded. Should use key service to fetch in future
// Maybe https://docs.nvidia.com/deploy/nvml-api/group__nvmlDeviceQueries.html#group__nvmlDeviceQueries_1g7f8088948ddf1183a817a4cb5566fba7
let nvidia_pem=[
  `-----BEGIN CERTIFICATE-----
MIIDfDCCAwKgAwIBAgIUTyx/6g3aReZxyc27+NGeL5u9+pwwCgYIKoZIzj0EAwMw
ZDEbMBkGA1UEBRMSNDFCODg0RDM0MjhDNTJGRTVDMQswCQYDVQQGEwJVUzEbMBkG
A1UECgwSTlZJRElBIENvcnBvcmF0aW9uMRswGQYDVQQDDBJHSDEwMCBBMDEgR1NQ
IEJST00wIBcNMjAxMDE3MDAwMDAwWhgPOTk5OTEyMzEyMzU5NTlaMHwxMTAvBgNV
BAUTKDRGMkM3RkVBMEREQTQ1RTY3MUM5Q0RCQkY4RDE5RTJGOUJCREZBOUMxCzAJ
BgNVBAYTAlVTMRswGQYDVQQKDBJOVklESUEgQ29ycG9yYXRpb24xHTAbBgNVBAMM
FEdIMTAwIEEwMSBHU1AgRk1DIExGMHYwEAYHKoZIzj0CAQYFK4EEACIDYgAEOd2x
BS5Kou5rq8gWbj/sML9gOmvYuY3jj+z45b78KTI5UUB4C090UVF2iaf6QUHna1no
kJlI9BPExpDxY0e2sICG3BIlvSoqlbj/tGUay5k8yv1Iqx+QaQUXG3bogFYKo4IB
WTCCAVUwDgYDVR0PAQH/BAQDAgeAMB0GA1UdDgQWBBQPLH/qDdpF5nHJzbv40Z4v
m736nDAfBgNVHSMEGDAWgBQ1JpgBn5505Vuohh2zBEPZAVsIVzA4BgNVHREEMTAv
oC0GCisGAQQBgxyCEgGgHwwdTlZJRElBOkdIMTAwOjQ4QjAyREIwRkM3QTgwMTEw
gcgGBmeBBQUEAQSBvTCBugIBATB2MBAGByqGSM49AgEGBSuBBAAiA2IABKoWhUeR
anRRqj8a3yL1mXofBAuKJzz1njoObdqMTx4xcEjLTeeXj7hqUDCt8+0rqfLYvoBC
JhL1WcHDz59m4NtAMTETApWorub/HRDdyyYwZAeht0rzuTqLDnAJJ3V/GzA9Bglg
hkgBZQMEAgIEMOw0kc3GSeYKAgVnOeN9a+WLSCP+dr4naHQsqM8EJEhxWLW1xyVp
0i0u6uVbAlPzGzAKBggqhkjOPQQDAwNoADBlAjB+kSHV51GZVvdUk3B+c5AZpL0o
OJAAkQeXbDDRowN5v0HnIqnQWqPoGi8fj7TvRXoCMQD4nkiHL8FcuKIGbgCSFtI1
iSGp6UM+Fu/NdWKmD8lUNeL9whS6F1jJsiT9nK7CSss=
-----END CERTIFICATE-----`,
`-----BEGIN CERTIFICATE-----
MIIDezCCAwKgAwIBAgIUbNlVQnrMDe+lArQIPkCyOmzjkkkwCgYIKoZIzj0EAwMw
ZDEbMBkGA1UEBRMSNDFCODg0RDM0MjhDNTJGRTVDMQswCQYDVQQGEwJVUzEbMBkG
A1UECgwSTlZJRElBIENvcnBvcmF0aW9uMRswGQYDVQQDDBJHSDEwMCBBMDEgR1NQ
IEJST00wIBcNMjAxMDE3MDAwMDAwWhgPOTk5OTEyMzEyMzU5NTlaMHwxMTAvBgNV
BAUTKDZDRDk1NTQyN0FDQzBERUZBNTAyQjQwODNFNDBCMjNBNkNFMzkyNDkxCzAJ
BgNVBAYTAlVTMRswGQYDVQQKDBJOVklESUEgQ29ycG9yYXRpb24xHTAbBgNVBAMM
FEdIMTAwIEEwMSBHU1AgRk1DIExGMHYwEAYHKoZIzj0CAQYFK4EEACIDYgAEI38+
IS2dC6V5USMyQ1eVS5G2PIQEEqwXpMZb6erT4WUfQWaGxW+E46iXhy1TDtaFu5in
E42R+J5nvM3Ou6xsxn6+ErlEZsYeqQsN4y35wqylXvXF0C6ZfsB64kOxXjwXo4IB
WTCCAVUwDgYDVR0PAQH/BAQDAgeAMB0GA1UdDgQWBBRs2VVCeswN76UCtAg+QLI6
bOOSSTAfBgNVHSMEGDAWgBQ1JpgBn5505Vuohh2zBEPZAVsIVzA4BgNVHREEMTAv
oC0GCisGAQQBgxyCEgGgHwwdTlZJRElBOkdIMTAwOjQ4QjAyREIwRkM3QTgwMTEw
gcgGBmeBBQUEAQSBvTCBugIBATB2MBAGByqGSM49AgEGBSuBBAAiA2IABKoWhUeR
anRRqj8a3yL1mXofBAuKJzz1njoObdqMTx4xcEjLTeeXj7hqUDCt8+0rqfLYvoBC
JhL1WcHDz59m4NtAMTETApWorub/HRDdyyYwZAeht0rzuTqLDnAJJ3V/GzA9Bglg
hkgBZQMEAgIEMFeAP5newGB9RvrO4D3cXDpAj864VwSVrxIWgqTKW5j06dX/ZwlL
HgbNtzQVSLQ8eTAKBggqhkjOPQQDAwNnADBkAjB4ApEVwwUIupfUhjvtn0niYKyG
q1ZDPzbXUVhmkPo1IT2Hps5Ax3KIFgXmv8Ap21cCMHspITJUllCw7pMYAWj5+YIw
YQbEJRnTb7vmq9L2hakPlzv+KfQEMUUaosZTD7v21A==
-----END CERTIFICATE-----`,
`
-----BEGIN CERTIFICATE-----
MIIDfTCCAwKgAwIBAgIUflqM/UIA6L4IL9dXQvzAxhbRtNQwCgYIKoZIzj0EAwMw
ZDEbMBkGA1UEBRMSNDE3RkQ0NUFGODk2OEJGOEQyMQswCQYDVQQGEwJVUzEbMBkG
A1UECgwSTlZJRElBIENvcnBvcmF0aW9uMRswGQYDVQQDDBJHSDEwMCBBMDEgR1NQ
IEJST00wIBcNMjAxMDE3MDAwMDAwWhgPOTk5OTEyMzEyMzU5NTlaMHwxMTAvBgNV
BAUTKDdFNUE4Q0ZENDIwMEU4QkUwODJGRDc1NzQyRkNDMEM2MTZEMUI0RDQxCzAJ
BgNVBAYTAlVTMRswGQYDVQQKDBJOVklESUEgQ29ycG9yYXRpb24xHTAbBgNVBAMM
FEdIMTAwIEEwMSBHU1AgRk1DIExGMHYwEAYHKoZIzj0CAQYFK4EEACIDYgAEcdpz
Fvu178i/cpn34e1LmuQEv1IbMvnHglGr+g0kU9ogfnbWyj8etsv0lnrVwAijQM9i
EC06K0aLgC8pRaNfIK032nIfvvM5jfHCs5cufG/1Zvlcb/Jpa41AXVu2s1vKo4IB
WTCCAVUwDgYDVR0PAQH/BAQDAgeAMB0GA1UdDgQWBBR+Woz9QgDovggv11dC/MDG
FtG01DAfBgNVHSMEGDAWgBSEYQ1fW4x4YesP8KN9IrgJSe6v2DA4BgNVHREEMTAv
oC0GCisGAQQBgxyCEgGgHwwdTlZJRElBOkdIMTAwOjQ4QjAyREI3RjU1NERBODAw
gcgGBmeBBQUEAQSBvTCBugIBATB2MBAGByqGSM49AgEGBSuBBAAiA2IABDuziQJx
0eYHpOmktClwB4b7GAEOx70Z4u3P3Tu/cAcGotrjlwvgg2PfpwM1UaYB5lw83e0F
TMwfSsEOoYiF+zteFUDAKkYdji6bOV3KUgsY5UieahRoIXi0LrcZAgaZvjA9Bglg
hkgBZQMEAgIEMFeAP5newGB9RvrO4D3cXDpAj864VwSVrxIWgqTKW5j06dX/ZwlL
HgbNtzQVSLQ8eTAKBggqhkjOPQQDAwNpADBmAjEA9Ahw/CTUMElJk8P/sfIXHioG
J60XWYWhf7szGkWD9JBXXLubISjvHBYgLD89RWlLAjEA1R1X3NZmv1yUDV5bTQAn
VDMAU0iWNMMbFUBMD5rhUX+/sI7LiafY7bodDvAO94X5
-----END CERTIFICATE-----
`
];
/**
 * Verifies a signature with a certificate
 * p384 curve is used, with sha384 hash
 *
 * @param {string} certificatePem - PEM encoded certificate to verify with
 * @param {string} signed_data - hexstring of data that was signed
 * @param {*} SIGNATURE_R  - hexstring of R value of signature
 * @param {*} SIGNATURE_S - hexstring of S value of signature
 * @returns boolean
 */

async function verify_signature_with_certificate(
  certificatePem,
  signed_data,
  SIGNATURE_R,
  SIGNATURE_S,
  TYPE=""
) {


  let curve=undefined;
  if (TYPE=="INTEL") {
    curve = new ec("p256");
    console.log("Intel certificate, using p256")
  } else {
    curve = new ec("p384");    
    console.log("Non Intel certificate, using p384")
  } 

  let publicKey = "";  
  if (certificatePem.indexOf("-----BEGIN CERTIFICATE-----")==-1) {
    console.log("Using raw public key")
    try {
      publicKey = curve.keyFromPublic(
        "04" + certificatePem,
        "hex"
      );
    } catch (error){
      console.debug(error)
      return false;
    }
  } else {
    console.log("Using public key from certificate")
    
    try {
      let certificate = forge.pki.certificateFromPem(certificatePem);
      publicKey = curve.keyFromPublic(
        certificate.publicKey.toString(),
        "hex" 
      );
    } catch (error){
      console.debug(error)
      return false;
    }
  }

  let signature = {
    r: SIGNATURE_R,
    s: SIGNATURE_S,
  };
  console.log("Signature")
  console.log(signature)

  const hexBuffer = Buffer.from(signed_data, "hex");

  let hash=undefined;
  if (TYPE=="INTEL") {
    hash = new Uint8Array(
      await crypto.subtle.digest("SHA-256", hexBuffer)
    );
  } else {
    hash = new Uint8Array(
      await crypto.subtle.digest("SHA-384", hexBuffer)
    );
  }

  let r = publicKey.verify(hash, signature);
  return r;
}
/**
 * Swaps the endianess of a hexadecimal string
 *
 * @param {*} hexString - hex string to swap
 * @returns
 */
function swapEndianness(hexString) {
  // Check if the hexString has an even length
  if (hexString.length % 2 !== 0) {
    console.error("Hexadecimal string must have an even length.");
    return null;
  }

  // Split the hexString into pairs of characters
  const pairs = hexString.match(/.{1,2}/g);

  // Reverse the order of the pairs
  const reversedPairs = pairs.reverse();

  // Join the reversed pairs back into a string
  const swappedHexString = reversedPairs.join("");

  return swappedHexString;
}
/**
 * Parses the AMD SEV attestation report based on the spec found int
 * https://www.amd.com/content/dam/amd/en/documents/epyc-technical-docs/specifications/56860.pdf
 * Includes ROOTFS and Signed Public Key parsing and correting R and S edianess
 *
 * @param {buffer} reportBuffer buffer representing the AMD report
 * @returns REPORT
 */
function parse_amd_attestation(reportBuffer) {
  // Spec:
  //Parse report
  let REPORT = {
    VERSION: binarySubArray(reportBuffer, 0x0, 32 / 8),
    GUEST_SVN: binarySubArray(reportBuffer, 0x4, 32 / 8),
    POLICY: binarySubArray(reportBuffer, 0x8, 64 / 8),
    FAMILY_ID: binarySubArray(reportBuffer, 0x10, 128 / 8),
    IMAGE_ID: binarySubArray(reportBuffer, 0x20, 128 / 8),
    VMPL: binarySubArray(reportBuffer, 0x30, 32 / 8),
    SIGNATURE_ALGO: binarySubArray(reportBuffer, 0x34, 32 / 8),
    CURRENT_TCB: binarySubArray(reportBuffer, 0x38, 64 / 8),
    PLATFORM_INFO: binarySubArray(reportBuffer, 0x40, 64 / 8),
    SIGNING_KEY_DETAILS: binarySubArray(reportBuffer, 0x40, 4 / 8),
    RESERVED1: binarySubArray(reportBuffer, 0x4c, 32 / 8),
    REPORT_DATA: binarySubArray(reportBuffer, 0x50, 512 / 8),
    MEASUREMENT: binarySubArray(reportBuffer, 0x90, 384 / 8),
    HOST_DATA: binarySubArray(reportBuffer, 0xc0, 256 / 8),
    ID_KEY_DIGEST: binarySubArray(reportBuffer, 0xe0, 384 / 8),
    AUTHOR_KEY_DIGEST: binarySubArray(reportBuffer, 0x110, 384 / 8),
    REPORT_ID: binarySubArray(reportBuffer, 0x140, 256 / 8),
    REPORT_ID_MA: binarySubArray(reportBuffer, 0x160, 256 / 8),
    REPORTED_TCB: binarySubArray(reportBuffer, 0x180, 64 / 8),
    RESERVED2: binarySubArray(reportBuffer, 0x188, 18 / 2),
    CHIP_ID: binarySubArray(reportBuffer, 0x1a0, 512 / 8),
    COMMITTED_TCB: binarySubArray(reportBuffer, 0x1e0, 64 / 8),
    CURRENT_BUILD: binarySubArray(reportBuffer, 0x1e8, 8 / 8),
    CURRENT_MINOR: binarySubArray(reportBuffer, 0x1e9, 8 / 8),
    CURRENT_MAJOR: binarySubArray(reportBuffer, 0x1ea, 8 / 8),
    RESERVER3: binarySubArray(reportBuffer, 0x1eb, 8 / 8),
    COMMITTED_BUILD: binarySubArray(reportBuffer, 0x1ec, 8 / 8),
    COMMITTED_MINOR: binarySubArray(reportBuffer, 0x1ed, 8 / 8),
    COMMITTED_MAJOR: binarySubArray(reportBuffer, 0x1ee, 8 / 8),
    RESERVER4: binarySubArray(reportBuffer, 0x1ef, 8 / 8),
    LAUNCH_TCB: binarySubArray(reportBuffer, 0x1f0, 64 / 8),
    RESERVER4: binarySubArray(reportBuffer, 0x1ef, 0x29f - 0x1f8),
    SIGNATURE: binarySubArray(reportBuffer, 0x2a0, 0x49f - 0x2a0),
    SIGNATURE_R: undefined,
    SIGNATURE_S: undefined,
    SIGNED_BYTES: binarySubArray(reportBuffer, 0x000, 0x29f + 1),
    REPORT_DATA_ROOTFS_HASH_HEX: undefined,
    REPORT_DATA_SINGING_PUBLIC_KEY_HEX: undefined,
  };

  REPORT.SIGNATURE_R = swapEndianness(
    binarySubArray(REPORT.SIGNATURE, 0x000, 576 / 8).toString("hex")
  );
  REPORT.SIGNATURE_S = swapEndianness(
    binarySubArray(REPORT.SIGNATURE, 0x048, 576 / 8).toString("hex")
  );

  let report_data = REPORT.REPORT_DATA.toString("hex");
  REPORT.REPORT_DATA_ROOTFS_HASH_HEX = report_data.substring(0, 64);
  REPORT.REPORT_DATA_SINGING_PUBLIC_KEY_HEX = report_data.substring(64);

  return REPORT;
}
/**
 * Parses the INTEL TDX "quote"  based on the spec found in
 * <TBD>
 * Includes ROOTFS and Signed Public Key parsing and correting R and S edianess
 *
 * @param {buffer} reportBuffer buffer representing the AMD report
 * @returns REPORT
 */
function parse_intel_attestation(reportBuffer) {
  // Spec:
  //Parse report
  const textDecoder = new TextDecoder('ascii');
/*  let REPORT = {
    CERT: textDecoder.decode(binarySubArray(reportBuffer,0x4ea,reportBuffer.length-0x4ea)).replace(/\0+$/, ''),
    SIGNATURE:binarySubArray(reportBuffer,0x482,0x4c2-0x482),
    SIGNED_BYTES2: binarySubArray(reportBuffer, 0x30 ,632-0x30),
    SIGNED_BYTES: binarySubArray(reportBuffer, 48 ,584),

    REPORT_DATA_ROOTFS_HASH_HEX:"",
    REPORT_DATA_SINGING_PUBLIC_KEY_HEX:"",
    SIGNATURE_R: "",
    SIGNATURE_S:"",
  };
*/
  let REPORT = {
    HEADER: binarySubArray(reportBuffer, 0,48),
    SIGNED_BYTES: binarySubArray(reportBuffer, 0,584+48),    
    SIGNED_BYTES_BODY_LEN: binarySubArray(reportBuffer, 48,584+4),
    USERDATA: binarySubArray(reportBuffer, 0x238,64),
    RTMR0:binarySubArray(reportBuffer,0x278,48),
    RTMR1:binarySubArray(reportBuffer,0x1a8,48),
    RTMR2:binarySubArray(reportBuffer,0x1d8,48),
    RTMR3:binarySubArray(reportBuffer,0x208,48),
    SIGNATURE:binarySubArray(reportBuffer,584+48+4,64),
    ECDSA_KEY:binarySubArray(reportBuffer,584+48+4+64,64),
    QE_SIGNED_BYTES:binarySubArray(reportBuffer,0x302,384),
    QE_ECDSA_HASH: binarySubArray(reportBuffer,0x442,32),
    QE_SIGNATURE:binarySubArray(reportBuffer,0x482,64),
    QE_SIGNATURE_R: "",
    QE_SIGNATURE_S:"",
    QE_AUTHENTICATION_DATA:binarySubArray(reportBuffer,0x4c4,0x20),  
    CERT: textDecoder.decode(binarySubArray(reportBuffer,0x4ea,reportBuffer.length-0x4ea)).replace(/\0+$/, ''),
    REPORT_DATA_ROOTFS_HASH_HEX:"",
    REPORT_DATA_SINGING_PUBLIC_KEY_HEX:"",
    SIGNATURE_R: "",
    SIGNATURE_S:"",
    MEASUREMENT:""
  };
  REPORT.MEASUREMENT="RTMR 0\n" + splitText(REPORT.RTMR0.toString("hex"),32) + "\nRTMR 1\n" + splitText(REPORT.RTMR1.toString("hex"),32) + "\nRTMR 2\n" + splitText(REPORT.RTMR2.toString("hex"),32) + "\nRTMR 3\n" + splitText(REPORT.RTMR3.toString("hex"),32);
//    REPORT.SIGNED_BYTES=REPORT.SIGNED_BYTES_BODY;
//  REPORT.SIGNED_BYTES=REPORT.SIGNED_BYTES_HEADER_BODY;
//  REPORT.SIGNED_BYTES=REPORT.SIGNED_BYTES_HEADER_BODY_LEN;
//  REPORT.SIGNED_BYTES=REPORT.SIGNED_BYTES_BODY_LEN;

  REPORT.SIGNATURE_R = (binarySubArray(REPORT.SIGNATURE, 0, 32).toString("hex"));
  REPORT.SIGNATURE_S = (binarySubArray(REPORT.SIGNATURE, 32, 32).toString("hex"));

  REPORT.QE_SIGNATURE_R = (binarySubArray(REPORT.QE_SIGNATURE, 0, 32).toString("hex"));
  REPORT.QE_SIGNATURE_S = (binarySubArray(REPORT.QE_SIGNATURE, 32, 32).toString("hex"));


  let report_userdata = REPORT.USERDATA.toString("hex");
  REPORT.REPORT_DATA_ROOTFS_HASH_HEX = report_userdata.substring(0, 64);
  REPORT.REPORT_DATA_SINGING_PUBLIC_KEY_HEX = report_userdata.substring(64);

  return REPORT;
}
/**
 * Parses the did of a VC DID to get the public key
 * Currently only support base58
 *
 * @param {string} VC DID
 * @returns public key hex string
 */
function parse_vc_did(did) {
  // Base 64 decode DID after did:key:z
  // TODO: z defines encoding type, assuming base58
  let did_pubkey_base58 = did.substring(9);
  let did_pubkey_buffer = bs58.decode(did_pubkey_base58);
  let did_pubkey_hex_padded = uint8ArrayToHexString(did_pubkey_buffer);
  let did_pubkey_hex = did_pubkey_hex_padded.substring(
    did_pubkey_hex_padded.length - 64,
    did_pubkey_hex_padded.length
  );
  return did_pubkey_hex;
}
function to_vc_did(pubkey) {
  const pubkey_buffer = hexStringToUint8Array("802402" + pubkey);
  let did_pubkey_bs58 = bs58.encode(pubkey_buffer);
  return "did:key:z" + did_pubkey_bs58;
}

async function Load() {
  var query = new URL(window.location.href).searchParams;
  var query_report = query.get("report");
  if (query_report) {
    let result = formatBase64(query_report, 76);
    document.getElementById("attestationReport").value = result;
  }
  var query_did = query.get("did");
  if (query_did) {
    document.getElementById("txtDID").value = query_did;
  }

  var rootfs = query.get("rootfs");
  if (rootfs) {
    document.getElementById("txtRootFS").value = rootfs;
  }
}

/**
 * Wraps a base64 string with newlines every n characters
 * @param {*} input - base64 string
 * @param {*} n - number of characters to wrap usually 32
 * @returns
 */
function formatBase64(input, n) {
  let output = "";
  input = input.replace(/\n/g, "");
  for (let i = 0; i < input.length; i += n) {
    output += input.slice(i, i + n) + "\n";
  }
  return output;
}

function binarySubArray(reportBuffer, offset, length) {
  return reportBuffer.slice(offset, offset + length);
}
function hexStringToUint8Array(hexString) {
  // Remove the leading "0x" if present
  hexString = hexString.startsWith("0x") ? hexString.slice(2) : hexString;

  // Check if the hex string has an even number of characters
  if (hexString.length % 2 !== 0) {
    throw new Error("Hex string must have an even number of characters");
  }

  // Create a new array of bytes
  const byteArray = new Uint8Array(hexString.length / 2);

  // Convert each pair of hex characters to a byte and store it in the array
  for (let i = 0; i < hexString.length; i += 2) {
    const byte = parseInt(hexString.substr(i, 2), 16);
    byteArray[i / 2] = byte;
  }

  return byteArray;
}

function uint8ArrayToHexString(uint8Array) {
  const hexChars = [];

  for (let i = 0; i < uint8Array.length; i++) {
    const hex = uint8Array[i].toString(16).padStart(2, "0");
    hexChars.push(hex);
  }

  return hexChars.join("");
}

function splitText(text, segmentLength) {
  let result = "";
  text=text.replace(/\n/g, "");
  for (let i = 0; i < text.length; i += segmentLength) {
    result += text.slice(i, i + segmentLength) + "\n";
  }
  return result;
}

async function VerifyNvidia() {
  if (document.body.classList.contains("is-finished")) {
    document.getElementById("attestationReport").value = "";
    location.reload();

    return;
  }
  document.body.classList.add("did-verification--pending");
  document.body.classList.add("verification--pending");
  document.body.classList.remove(
    "verification--success",
    "verification--failed"
  );
  let report = document.getElementById("attestationReport").value;
  if (report == "") return;

  document.getElementById("submitLabel").innerHTML = "Verifying";

  const reportBuffer = Buffer.from(report, "base64");
  let nvr = reportBuffer.toString("hex");

  let reportVersion=-1;
  
  if (nvr.length==8106) reportVersion=0;
  if (nvr.length==8224) reportVersion=1;
  if (reportVersion==-1 ) {
    alert("Report verion  unknown " + nvr.length)
  } 

  var notaryPublicKey = nvr.substring(8, 64 + 8);

  if (!document.getElementById("txtDID").value)
    document.getElementById("txtDID").value = to_vc_did(notaryPublicKey);

  var expectedNotaryPublicKey = parse_vc_did(
    document.getElementById("txtDID").value
  );

  const DIDValid = notaryPublicKey == expectedNotaryPublicKey;

  var SIGNATURE = nvr.substring(nvr.length - 192);
  nvr = nvr.substring(0, nvr.length - 192);
  let SIGNATURE_R = SIGNATURE.substring(0, 96);
  let SIGNATURE_S = SIGNATURE.substring(96);

  /*    OFFSET   - FIELD                   - SIZE(in bytes)
    0        - SPDMVersion             - 1
    1        - RequestResponseCode     - 1
    2        - Param1                  - 1
    3        - Param2                  - 1
    4        - Nonce                   - 32
    36       - SlotIDParam             - 1
*/
  let REPORT = {
    SPDMVersion: nvr.substring(0 * 2, 0 * 2 + 1 * 2),
    RequestResponseCode: nvr.substring(1 * 2, 1 * 2 + 1 * 2),
    Param1: nvr.substring(2 * 2, 2 * 2 + 1 * 2),
    Param2: nvr.substring(3 * 2, 3 * 2 + 1 * 2),
    nonce: nvr.substring(4 * 2, 4 * 2 + 32 * 2),
    SlotIDParam: nvr.substring(36 * 2, 36 * 2 + 1 * 2),
    OPAQUE_VALUES: undefined,
  };
  let nvrr = nvr.substring(37 * 2, nvr.length);

  // Parse opaqe
  let reportSpecific=0
  if (reportVersion==1) reportSpecific=118;
  let opaque = nvrr.substring(nvrr.length - 716 - reportSpecific, nvrr.length);
console.log(opaque)
console.log("====")
  let OPAQUE_REPORT = {};
  const OPAQUE_DATA_TYPES = {
    1: "OPAQUE_FIELD_ID_CERT_ISSUER_NAME",
    2: "OPAQUE_FIELD_ID_CERT_AUTHORITY_KEY_IDENTIFIER",
    3: "OPAQUE_FIELD_ID_DRIVER_VERSION",
    4: "OPAQUE_FIELD_ID_GPU_INFO",
    5: "OPAQUE_FIELD_ID_SKU",
    6: "OPAQUE_FIELD_ID_VBIOS_VERSION",
    7: "OPAQUE_FIELD_ID_MANUFACTURER_ID",
    8: "OPAQUE_FIELD_ID_TAMPER_DETECTION",
    9: "OPAQUE_FIELD_ID_SMC",
    10: "OPAQUE_FIELD_ID_VPR",
    11: "OPAQUE_FIELD_ID_NVDEC0_STATUS",
    12: "OPAQUE_FIELD_ID_MSRSCNT",
    13: "OPAQUE_FIELD_ID_CPRINFO",
    14: "OPAQUE_FIELD_ID_BOARD_ID",
    15: "OPAQUE_FIELD_ID_CHIP_SKU",
    16: "OPAQUE_FIELD_ID_CHIP_SKU_MOD",
    17: "OPAQUE_FIELD_ID_PROJECT",
    18: "OPAQUE_FIELD_ID_PROJECT_SKU",
    19: "OPAQUE_FIELD_ID_PROJECT_SKU_MOD",
    255: "OPAQUE_FIELD_ID_INVALID",
  };
  for (var x = 0; x < opaque.length; x++) {
    var type = opaque[x + 2] + opaque[x + 3] + opaque[x] + opaque[x + 1];
    x = x + 4;
    var size = opaque[x + 2] + opaque[x + 3] + opaque[x] + opaque[x + 1];
    x = x + 4;

    type = parseInt(type, 16);
    size = parseInt(size, 16);
    let value = "";
    for (var y = 0; y < size * 2; y++) {
      value = value + opaque[x + y] + "" + opaque[x + y + 1];
      y = y + 1;
    }
    x = x + size * 2;
    OPAQUE_REPORT[OPAQUE_DATA_TYPES[type]] = value;
    x = x - 1;
  }
  REPORT.OPAQUE_VALUES = OPAQUE_REPORT;
  
  console.log(REPORT.OPAQUE_VALUES)

  let cert = nvidia_pem[reportVersion];
  if (REPORT.OPAQUE_VALUES.OPAQUE_FIELD_ID_BOARD_ID == "08050000") {
    console.log("OKKKKK")
    cert = nvidia_pem[2]
  }
  let nvidia_valid = await verify_signature_with_certificate(
    cert,
    nvr,
    SIGNATURE_R,
    SIGNATURE_S
  );

  let nvidia_result = "";
  let submit_btn_text = "";

  document.getElementById("txtResult").innerHTML = "";
  if (nvidia_valid) {
    let VBIOS =
      REPORT.OPAQUE_VALUES.OPAQUE_FIELD_ID_VBIOS_VERSION.match(/.{1,2}/g).join(
        ":"
      );      
    let DRIVER = REPORT.OPAQUE_VALUES.OPAQUE_FIELD_ID_DRIVER_VERSION;
    // document.getElementById("txtResult").innerHTML +=
    nvidia_result += "NVIDIA Attestation Report signature verification passed";
    nvidia_result += "<br>NVIDIA VBIOS: " + VBIOS;
    submit_btn_text = "Verified";
    
  } else {
    submit_btn_text = "Unverified";
    nvidia_result +=
      "<br>NVIDIA Attestation Report signature verification failed";
  }

  document.getElementById("txtResult").innerHTML = nvidia_result;

  document.body.classList.remove("verification--pending");
  document.body.classList.add(
    nvidia_valid ? "verification--success" : "verification--failed"
  );

  const setValidationResult = () => {
    document.body.classList.remove("did-verification--pending");

    document.body.classList.add(
      DIDValid ? "did-verification--success" : "did-verification--failed"
    );

    if (nvidia_valid && DIDValid) {
      document.body.classList.add("all-verified");
      document.getElementById("submitLabel").innerHTML = submit_btn_text;
      document.getElementById("originalTitle").innerHTML =
        "Attestation & Key Verified";
    }
    ////

    if (DIDValid) {
      document.getElementById("did-result").innerHTML =
        "NVIDIA Attestation Report contains the DID";
    } else {
      document.getElementById("did-result").innerHTML =
        "NVIDIA Attestation Report does not contain the DID";
    }

    ////

    document.body.classList.add("is-finished");
    document.getElementById("submitLabel").innerHTML = "Restart";
  };

  if (nvidia_valid && DIDValid) {
    verifiedAnimation.addEventListener("complete", () => {
      setValidationResult();
    });
    verifiedAnimation.play();
  } else {
    setValidationResult();
  }
}
async function VerifyIntel() {
  if (document.body.classList.contains("is-finished")) {
    document.getElementById("attestationReport").value = "";
    document.getElementById("txtDID").value = "";
    document.getElementById("txtRootFS").value = "";
    location.reload();
    return;
  }
  let report = document.getElementById("attestationReport").value;
  if (report == "") return;

  document.body.classList.add("did-verification--pending");
  document.body.classList.add("file-verification--pending");
  document.body.classList.add("report-verification--pending");

  const reportBuffer = Buffer.from(report, "base64");
  let REPORT = parse_intel_attestation(reportBuffer);

  if (!document.getElementById("txtDID").value)
    document.getElementById("txtDID").value = to_vc_did(
      REPORT.REPORT_DATA_SINGING_PUBLIC_KEY_HEX
    );
  let did_pubkey_hex = parse_vc_did(document.getElementById("txtDID").value);
  const DIDValid = REPORT.REPORT_DATA_SINGING_PUBLIC_KEY_HEX == did_pubkey_hex;

  let intel_pem=REPORT.CERT.split("-----BEGIN CERTIFICATE-----")[1];

  
  
  let hash = new Uint8Array(
    await crypto.subtle.digest("SHA-256", REPORT.ECDSA_KEY)
  );

  let verify_enclave = await verify_signature_with_certificate(
    REPORT.ECDSA_KEY.toString("hex"),
    REPORT.SIGNED_BYTES,
    REPORT.SIGNATURE_R,
    REPORT.SIGNATURE_S,
    "INTEL"
  );
  
  // Check QE signs Enclave key
  let enclave_report = hexStringToUint8Array(REPORT.ECDSA_KEY.toString("hex") + REPORT.QE_AUTHENTICATION_DATA.toString("hex"));
  let hash_ECDSA = new Uint8Array(
    await crypto.subtle.digest("SHA-256", enclave_report)
  );  
  let verified_enclave_QE = false;
  if (uint8ArrayToHexString(hash_ECDSA) ==  REPORT.QE_ECDSA_HASH.toString("hex")) {
    verified_enclave_QE=true;
  }
  let verified_qe = await verify_signature_with_certificate(
    "-----BEGIN CERTIFICATE-----" + intel_pem,
    REPORT.QE_SIGNED_BYTES,
    REPORT.QE_SIGNATURE_R,
    REPORT.QE_SIGNATURE_S,
    "INTEL"
  );

  let vcek_pem_valid  = false;
  vcek_pem_valid  = verify_enclave && verified_qe && verified_enclave_QE;

  // Extract rootfs and public key from report data
  let rootfs_hash = REPORT.REPORT_DATA_ROOTFS_HASH_HEX; // report_data.substring(0,64);
  let public_key = REPORT.REPORT_DATA_SINGING_PUBLIC_KEY_HEX; //report_data.substring(64);

  document.body.classList.remove("report-verification--pending");
  document.body.classList.add(
    vcek_pem_valid
      ? "report-verification--success"
      : "report-verification--failed"
  );

  if (!document.getElementById("txtRootFS").value)
    document.getElementById("txtRootFS").value =
      REPORT.REPORT_DATA_ROOTFS_HASH_HEX;
  const reportHashValid = document.getElementById("txtRootFS").value;
  
  const setValidationResult = () => {
    // DID
    document.body.classList.remove("did-verification--pending");
    document.body.classList.remove("file-verification--pending");

    document.body.classList.add(
      DIDValid ? "did-verification--success" : "did-verification--failed"
    );

    if (DIDValid) {
      document.getElementById("did-result").innerHTML =
        "Intel Quote contains the DID";
      // AMD VBIOS: 00:74:00:96:1c:00:00:00
    } else {
      document.getElementById("did-result").innerHTML =
        "Intel Quote does not contain the DID";
    }

    if (vcek_pem_valid) {
      document.getElementById("report-result").innerHTML =
        "Intel Quote signature verification passed";
      // AMD VBIOS: 00:74:00:96:1c:00:00:00";

      document.getElementById("attestationDecode").style.display = "block";

      let d = document.getElementById("attestationReport");

      d.value = "";
      d.value +=
        "MEASUREMENT\n" + REPORT.MEASUREMENT.toString("hex");
      // d.value +=
      //   "\n\nAUTHOR KEY DIGEST\n" +
      //   splitText(REPORT.AUTHOR_KEY_DIGEST.toString("hex"), 32);
      d.value += "\n\nNOTARY KEY\n" + splitText(public_key, 32);
      d.value += "\n\nFILE SYSTEM HASH\n" + splitText(rootfs_hash, 32);
    } else {
      document.getElementById("report-result").innerHTML =
        "Intel Quote signature verification failed";
      // AMD VBIOS: 00:74:00:96:1c:00:00:00";
    }

    const correctHash =
    rootfs_hash == document.getElementById("txtRootFS").value;

  if (reportHashValid) {
    if (correctHash) {
      document.body.classList.add("file-verification--success");
      document.getElementById("file-result").innerHTML =
        "Intel Quote contains the file system hash";
      // AMD VBIOS: 00:74:00:96:1c:00:00:00";
    } else {
      document.body.classList.add("file-verification--failed");
      document.getElementById("file-result").innerHTML =
        "Intel Quote does not contain the file system hash";
      // AMD VBIOS: 00:74:00:96:1c:00:00:00";
    }
  }
  if (reportHashValid && correctHash && DIDValid && vcek_pem_valid) {
    document.body.classList.add("all-verified");
    document.getElementById("originalTitle").innerHTML =
      "Attestation, Key and Hash Verified";
  }
};

if (vcek_pem_valid && DIDValid && reportHashValid) {
  verifiedAnimation.addEventListener("complete", () => {
    setValidationResult();
  });
  verifiedAnimation.play();
} else {
  setValidationResult();
}


}
async function VerifyAMD() {
  if (document.body.classList.contains("is-finished")) {
    document.getElementById("attestationReport").value = "";
    document.getElementById("txtDID").value = "";
    document.getElementById("txtRootFS").value = "";
    location.reload();
    return;
  }
  let report = document.getElementById("attestationReport").value;
  if (report == "") return;

  document.body.classList.add("did-verification--pending");
  document.body.classList.add("file-verification--pending");
  document.body.classList.add("report-verification--pending");

  const reportBuffer = Buffer.from(report, "base64");
  let REPORT = parse_amd_attestation(reportBuffer);

  // Fill in DID if not entered
  if (!document.getElementById("txtDID").value)
    document.getElementById("txtDID").value = to_vc_did(
      REPORT.REPORT_DATA_SINGING_PUBLIC_KEY_HEX
    );

  // pares did
  let did_pubkey_hex = parse_vc_did(document.getElementById("txtDID").value);

  const DIDValid = REPORT.REPORT_DATA_SINGING_PUBLIC_KEY_HEX == did_pubkey_hex;
  let vcek_pem="";
  let chip_id=uint8ArrayToHexString(REPORT.CHIP_ID).toLowerCase();
  console.log(chip_id);
  // Version information
  let version = uint8ArrayToHexString(REPORT.COMMITTED_TCB)
  let blSPL=parseInt(version.substring(0, 2),16); blSPL=blSPL<10?"0"+blSPL:blSPL;
  let teeSPL=parseInt(version.substring(3, 2),16);teeSPL=teeSPL<10?"0"+teeSPL:teeSPL;
  let snpSPL=parseInt(version.substring(version.length - 4,version.length-2),16);snpSPL=snpSPL<10?"0"+snpSPL:snpSPL;
  let ucodeSPL=parseInt(version.substring(version.length - 2),16);ucodeSPL=ucodeSPL<10?"0"+ucodeSPL:ucodeSPL;
  let chip_version  = "blSPL" +blSPL + "teeSPL" + teeSPL + "snpSPL" + snpSPL + "ucodeSPL" + ucodeSPL;

  if (!vcek_pem_db[chip_id]) vcek_pem_db[chip_id]={}

  let response=undefined;
  if (!vcek_pem_db[chip_id][chip_version]){
    let url = "https://kdsintf.amd.com/vcek/v1/Milan/" + chip_id + "?blSPL=" + blSPL +"&teeSPL=" + teeSPL + "&snpSPL=" + snpSPL +"&ucodeSPL=" + ucodeSPL + ""    
    url = "https://node2.e-mesh.net/vcek/v1/Milan/" + chip_id + "?blSPL=" + blSPL +"&teeSPL=" + teeSPL + "&snpSPL=" + snpSPL +"&ucodeSPL=" + ucodeSPL + ""
    try {
      response = await fetch(url);
    } catch (error) {
      url = "https://kdsintf.amd.com/vcek/v1/Milan/" + chip_id + "?blSPL=" + blSPL +"&teeSPL=" + teeSPL + "&snpSPL=" + snpSPL +"&ucodeSPL=" + ucodeSPL + ""    
      alert("AMD chip's public key for this specific version is not found in database. (" + chip_version + ")\n\nYou can download it at " + url);
      return
    }
    console.log("Downloaded Certificate")
    let b = new Buffer(await response.arrayBuffer())
    let base64String=b.toString("base64")
  
    const pemString = `-----BEGIN CERTIFICATE-----\n${base64String.match(/.{1,64}/g).join('\n')}\n-----END CERTIFICATE-----`;

    let value = localStorage.getItem('vcomp-vcek');
    if (!value) value={}
    if (!value[chip_id]) value[chip_id]={}
    value[chip_id][chip_version]=pemString
    localStorage.setItem('vcomp-vcek', JSON.stringify(value));
    vcek_pem_db[chip_id][chip_version]=pemString

  }

  

  if (!vcek_pem_db[chip_id][chip_version]){
    alert("AMD chip's public key for this specific version is not found in database. (" + chip_version + ")");
    return;
  }
  vcek_pem=vcek_pem_db[chip_id][chip_version];

  // Verify signature of AMD attestation
  let vcek_pem_valid = await verify_signature_with_certificate(
    vcek_pem,
    REPORT.SIGNED_BYTES,
    REPORT.SIGNATURE_R,
    REPORT.SIGNATURE_S
  );
  // Extract rootfs and public key from report data
  let rootfs_hash = REPORT.REPORT_DATA_ROOTFS_HASH_HEX; // report_data.substring(0,64);
  let public_key = REPORT.REPORT_DATA_SINGING_PUBLIC_KEY_HEX; //report_data.substring(64);

  document.body.classList.remove("report-verification--pending");
  document.body.classList.add(
    vcek_pem_valid
      ? "report-verification--success"
      : "report-verification--failed"
  );

  // Fill in FS HASH if not entered
  if (!document.getElementById("txtRootFS").value)
    document.getElementById("txtRootFS").value =
      REPORT.REPORT_DATA_ROOTFS_HASH_HEX;

  const reportHashValid = document.getElementById("txtRootFS").value;

  const setValidationResult = () => {
    // DID
    document.body.classList.remove("did-verification--pending");

    document.body.classList.add(
      DIDValid ? "did-verification--success" : "did-verification--failed"
    );

    if (DIDValid) {
      document.getElementById("did-result").innerHTML =
        "AMD Attestation Report contains the DID";
      // AMD VBIOS: 00:74:00:96:1c:00:00:00
    } else {
      document.getElementById("did-result").innerHTML =
        "AMD Attestation Report does not contain the DID";
    }

    // END DID
    // REport
    if (vcek_pem_valid) {
      document.getElementById("report-result").innerHTML =
        "AMD attestation Report signature verification passed";
      // AMD VBIOS: 00:74:00:96:1c:00:00:00";

      document.getElementById("attestationDecode").style.display = "block";

      let d = document.getElementById("attestationReport");

      d.value = "";
      d.value +=
        "MEASUREMENT\n" + splitText(REPORT.MEASUREMENT.toString("hex"), 32);
      // d.value +=
      //   "\n\nAUTHOR KEY DIGEST\n" +
      //   splitText(REPORT.AUTHOR_KEY_DIGEST.toString("hex"), 32);
      d.value += "\n\nNOTARY KEY\n" + splitText(public_key, 32);
      d.value += "\n\nFILE SYSTEM HASH\n" + splitText(rootfs_hash, 32);
    } else {
      document.getElementById("report-result").innerHTML =
        "AMD attestation Report signature verification failed";
      // AMD VBIOS: 00:74:00:96:1c:00:00:00";
    }
    // end report
    // HAsh
    document.body.classList.remove("file-verification--pending");
    document.body.classList.add("is-finished");
    document.getElementById("submitLabel").innerHTML = "Restart";

    const correctHash =
      rootfs_hash == document.getElementById("txtRootFS").value;

    if (reportHashValid) {
      if (correctHash) {
        document.body.classList.add("file-verification--success");
        document.getElementById("file-result").innerHTML =
          "AMD ATTESTATION REPORT contains the file system hash";
        // AMD VBIOS: 00:74:00:96:1c:00:00:00";
      } else {
        document.body.classList.add("file-verification--failed");
        document.getElementById("file-result").innerHTML =
          "AMD ATTESTATION REPORT does not contain the file system hash";
        // AMD VBIOS: 00:74:00:96:1c:00:00:00";
      }
    }
    if (reportHashValid && correctHash && DIDValid && vcek_pem_valid) {
      document.body.classList.add("all-verified");
      document.getElementById("originalTitle").innerHTML =
        "Attestation, Key and Hash Verified";
    }
  };

  if (vcek_pem_valid && DIDValid && reportHashValid) {
    verifiedAnimation.addEventListener("complete", () => {
      setValidationResult();
    });
    verifiedAnimation.play();
  } else {
    setValidationResult();
  }
}

if (document.getElementById("btnVerify")) {
  verifiedAnimation = lottie.loadAnimation({
    container: animationHolder,
    path: "assets/animation/AMD_Verification_Animation.json",
    renderer: "svg",
    loop: false,
    autoplay: false,
  });
  document.getElementById("btnVerify").addEventListener("click", VerifyAMD);
}
if (document.getElementById("btnVerifyNvidia")) {
  verifiedAnimation = lottie.loadAnimation({
    container: animationHolder,
    path: "assets/animation/NVIDIA_Verification_Animation.json",
    renderer: "svg",
    loop: false,
    autoplay: false,
  });
  document
    .getElementById("btnVerifyNvidia")
    .addEventListener("click", VerifyNvidia);
}
if (document.getElementById("btnVerifyIntel")) {
  verifiedAnimation = lottie.loadAnimation({
    container: animationHolder,
    path: "assets/animation/INTEL_Verification_Animation.json",
    renderer: "svg",
    loop: false,
    autoplay: false,
  });
  document.getElementById("btnVerifyIntel").addEventListener("click", VerifyIntel);
}
Load();